.signup-container {
  width: 95%;
  max-width: 600px;
  background: #1f1f1f(0 12% 88%);
  margin: auto;
  padding: 1rem 0rem;
  box-sizing: border-box;
  color: rgb(255, 255, 255);
  font-size: 0.8rem;
}
.loginsignup-field {
  width: 60%;
  max-width: 25rem;
  max-height: 2rem;
  background: rgb(224, 223, 223);
  border: 1px solid black;
  padding: 0.5rem 0.5rem;
  box-sizing: border-box;
  /* right: ; */
}

.form-input {
  display: flex;
  flex-direction: column;
  margin: 1rem 0rem;
  /* max-width: 65rem; */
  justify-content: space-between;
  align-items: end;

  .form-input-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.required-field::after {
  content: "*";
  color: red;
}

.error {
  position: relative;
  font-size: smaller;
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
