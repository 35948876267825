.order-details {
  width: 45rem;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .order-header {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    h2 {
      margin-bottom: 10px;
    }

    .status,
    .transaction-status {
      font-weight: bold;
      text-transform: uppercase;
      padding: 5px 10px;
      border-radius: 4px;
    }

    .status.confirmed {
      background-color: #4caf50;
      color: white;
    }

    .transaction-status.success {
      background-color: #2196f3;
      color: white;
    }

    .status.failed,
    .transaction-status.failed {
      background-color: #f44336;
      color: white;
    }
  }

  .buyer-info,
  .address-info,
  .order-summary {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 10px;
      border-bottom: 2px solid #eee;
      padding-bottom: 5px;
    }

    p {
      margin: 5px 0;
    }
  }

  .order-items {
    margin-bottom: 20px;

    h3 {
      margin-bottom: 10px;
      border-bottom: 2px solid #eee;
      padding-bottom: 5px;
    }

    .order-item {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      padding: 10px;
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

      img {
        width: 80px;
        height: 80px;
        margin-right: 15px;
        border-radius: 4px;
        object-fit: cover;
      }

      .item-details {
        p {
          margin: 3px 0;
        }
      }
    }
  }
}

input {
  height: 2.6rem;
  padding-left: 20px;
  border: 1px solid #c9c9c9;
  outline: none;
  color: #5c5c5c;
  font-size: 1rem;
  box-sizing: border-box;
}
