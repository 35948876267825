.confirm-order-outer-container {
  margin: 2rem 0;
  border: 0.2rem solid rgb(255, 191, 0);
  padding: 2rem;
  border-radius: 1rem;
  min-height: 20rem;

  .cartitems-format-main {
    display: grid;
    grid-template-columns: 0.5fr 2.5fr 0.5fr 0.5fr 0.5fr;
    align-items: center;
    gap: 50px;
    padding: 20px 0px;
    color: #454545;
    font-size: 16px;
    font-weight: 600;
  }
  .cartitems-format {
    font-size: 16px;
    font-weight: 500;
  }
  .cartitems-product-icon {
    height: 55px;
  }

  .cartitems-quantity {
    width: 55px;
    height: 45px;
    border: 2px solid #240f0f;
    background: rgb(0, 0, 0);
  }

  .cancel {
    text-decoration: line-through;
  }

  @media (max-width: 1024px) {
    .cartitems {
      margin: 60px 8%;
    }
    .cartitems-format-main {
      grid-template-columns: 0.5fr 2.5fr 0.5fr 0.5fr 0.5fr;
      gap: 20px;
      padding: 15px 0px;
      font-size: 15px;
    }
    .cartitems-format {
      font-size: 15px;
    }
    .carticon-product-icon {
      height: 50px;
    }
    .cartitems-remove-icon {
      margin: auto;
    }
    .cartitems-quantity {
      width: 40px;
      height: 30px;
    }
    .cartitems-down {
      margin: 80px 0px;
      flex-wrap: wrap;
      gap: 40px;
    }
    .cartitems-total {
      margin: 0;
      min-width: 300px;
    }
    .cartitems-total button {
      max-width: 200px;
      height: 45px;
      font-size: 13px;
    }
  }
  @media (max-width: 500px) {
    .cartitems-format-main {
      display: none;
    }
    .cartitems-format {
      display: grid;
    }
    .cartitems-format-main {
      grid-template-columns: 0.5fr 3fr 0.5fr;
      gap: 10px;
    }
  }
}
