.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px -2px black;
  padding: 0.5rem 2rem;
  background-color: rgb(4, 5, 5);
}
.nav-logo {
  display: flex;
  align-items: center;
  gap: 10px;
}
.nav-logo img {
  width: 5.3rem;
  padding: 0;
}
.nav-logo p {
  color: #ffffff;
  font-size: 28px;
  font-weight: 600;
}
.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 40px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}

.nav-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3px;
  cursor: pointer;
}
.nav-menu hr {
  border: none;
  width: 80%;
  height: 3px;
  border-radius: 10px;
  background: #ff4141;
}
.nav-login-cart {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #ffffff;
}

.login-button {
  width: 130px;
  height: 45px;
  outline: none;
  border: 1px solid #ffffff;
  border-radius: 75px;
  color: #000000;
  font-size: 1rem;
  font-weight: 600;
  background: white;
  cursor: pointer;
}
.nav-login-cart button:active {
  background: #787070;
}
.nav-login-cart img {
  width: 35px;
}
.nav-cart-count {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1.8rem;
  margin-left: -3.6rem;
  font-size: 14px;
  background: red;
  color: white;
}

.nav-right {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.nav-dropdown {
  width: 1.5rem;
}

/* -------- media query---------- */
@media (max-width: 900px) {
  .nav {
    padding: 1rem 1rem;
  }
  .nav-menu {
    gap: 15px;
  }
}

@media (max-width: 750px) {
  .nav {
    padding: 1rem 1rem;
  }
  .nav-login-cart {
    gap: 15px;
  }
  .nav-dropdown {
    display: block;
    width: 30px;
    transition: 0.5s;
    padding: 0.4rem;
  }
  .nav-menu {
    display: none;
    height: 80px;
    width: 100%;
    position: absolute;
    background-color: rgb(0, 0, 0);
    justify-content: center;
    top: 60px;
    left: 0;
  }

  .nav-right {
    gap: 0;
  }

  .nav-menu-visible {
    display: flex;
  }

  .nav-dropdown.open {
    transform: rotate(90deg);
  }
  .nav-cart-count {
    margin-left: -2.35rem;
  }
}

@media (max-width: 500px) {
  .nav {
    padding: 1rem 0.5rem;
  }

  .nav-logo img {
    width: 6rem;
  }
  .nav-logo p {
    font-size: 18px;
  }
  .nav-menu-visible {
    height: 70px;
    top: 60px;
  }
  .nav-login-cart {
    transform: scale(0.8);
  }
  .nav-login-cart {
    gap: 10px;
  }
}
