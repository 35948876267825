.orders-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 3rem 6rem;

  h2 {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    margin: 1rem 0;
  }

  h3 {
    color: rgb(246, 161, 49);
  }

  .order-list-container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
  }
}

@media (max-width: 1024px) {
  .orders-outer-container {
    margin: 2rem 2rem;
    // gap: 1rem;
    // height: 8rem;
    // flex-direction: column;

    .user-information-block {
      font-size: 1rem;
      flex-direction: column;
      height: auto;
      gap: 0.5rem;
    }
  }
}
