.hero {
  min-height: 18vh;
  background: linear-gradient(180deg, #fde1ff, #e1ffea22 60%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 9%;
}
.hero-left {
  display: flex;
  line-height: 1.1;
  font-weight: 800;

  .shoora {
    width: 2.8rem;
    padding: 0 10px;
  }
}
.hero-left h2 {
  color: #090909;
  font-size: 1.6em;
}

.hero-left p {
  color: #171717;
  font-size: 2em;
  font-weight: 700;
}
.hero-hand-icon {
  display: flex;
  align-items: center;
  gap: 20px;
}
.hero-hand-icon img {
  width: 70px;
}
.hero-latest-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 250px;
  height: 60px;
  border-radius: 75px;
  margin-top: 20px;
  background: #ff4141;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.hero-latest-btn img {
  width: 18px;
}

/* .hero-right img{
    width: 450px;
} */

@media (max-width: 1200px) {
  .hero {
    padding: 10px 6%;
  }
  .hero-left p {
    font-size: 5em;
  }
  .hero-right img {
    width: 400px;
  }
}
@media (max-width: 900px) {
  .hero-left p {
    font-size: 4.5em;
  }
  .hero-right img {
    width: 350px;
  }
}
@media (max-width: 700px) {
  .hero {
    padding: 0 8%;
  }
  .hero-left p {
    font-size: 1.5em;
  }
  .hero-right {
    display: none;
  }
  .hero-left {
    line-height: 1.2;
  }
  .hero-left h2 {
    margin-bottom: 0.3em;
    font-size: 1.5rem;
  }

  .hero-latest-btn {
    margin-top: 30px;
  }
}

.hindi-tagline {
  padding: 15px 0px;
  color: rgb(171, 37, 0);
}
