.shipping-policy-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 2rem;
  margin: 3rem 6rem;
  min-height: 70vh;
  padding-bottom: 5rem;

  h2 {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    margin: 1rem 0;
  }

  h3 {
    color: rgb(246, 161, 49);
  }
}

@media (max-width: 1024px) {
  .shipping-policy-outer-container {
    margin: 2rem 2rem;
    gap: 1rem;
  }
}
