.kyc-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 3rem 6rem;

  h2 {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    margin: 1rem 0;
  }

  .kyc-image {
    max-width: 23vw;
    max-height: 8rem;
  }

  input,
  select {
    width: 80%;
    display: flex;
    margin-top: 0.4rem;
    padding: 4px 12px;
    border-radius: 50px;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }

  input[type="text"]:focus,
  input[type="email"]:focus,
  input:focus {
    border-bottom: 2px solid #007bff;
    outline: none;
  }
}

@media (max-width: 1024px) {
  .kyc-outer-container {
    margin: 1rem 2rem;

    h2 {
      font-size: 1rem;
    }

    .kyc-detail {
      width: 20rem;
      font-size: 0.6rem;
    }
  }
}
