.order-outer-container {
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  background-color: bisque;
  padding: 1.2rem 2.4rem;
  min-height: 6rem;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;

  .order-id {
    font-size: 0.6rem;
    color: blue;
    font-weight: 800;
  }

  .order-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.8rem;
    width: 100%;
    &-left {
      display: flex;
      gap: 1.4rem;
    }

    &-right {
      display: flex;
    }
  }

  .order-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;

    &-title {
      font-weight: 1000;
      color: gray;
    }

    &-row {
      display: flex;
      flex-direction: row;
      gap: 0.6rem;

      p {
        color: rgb(79, 79, 79);
      }
    }

    p {
      color: rgb(79, 79, 79);
    }
  }

  .order-items-list {
    display: flex;
    gap: 1.6rem;
    margin: 1.4rem 0 0 0;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    border-radius: 0.4rem;
    flex-wrap: wrap;
  }
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-text {
  visibility: hidden;
  width: 20rem;
  background-color: black;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  margin-top: 10px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
  color: azure;
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1024px) {
  .order-outer-container {
    padding: 1rem 1rem;
    gap: 1rem;
    min-height: 8rem;
    flex-direction: column;

    .order-id {
      font-size: 0.52rem;
    }

    .order-detail {
      font-size: 0.65rem;

      &-row {
        font-size: 0.65rem;
        gap: 0.3rem;
      }

      &-bottom {
        &-left {
          gap: 1rem;
        }
      }
    }

    .order-details {
      flex-direction: column;
      gap: 1rem;
    }

    .tooltip-text {
      width: 15rem;
    }

    .order-items-list {
      width: 90%;
      margin: 0;
      gap: 0.6rem;
    }
  }
}
