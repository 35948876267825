.new-collections {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-bottom: 100px;
  background-color: rgb(255, 244, 230);
}
.new-collections h1 {
  color: #8f8f8f;
  font-size: 2em;
  font-weight: 600;
  margin-top: 2em;
}
.new-collections hr {
  width: 350px;
  height: 4px;
  border-radius: 10px;
  background: #252525;
}
.collections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 60px auto;
  gap: 30px;
  width: 100%;
  max-width: 1280px;
}
