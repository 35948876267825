.item {
  width: 21%;
  position: relative;
}
.item img {
  width: 100%;
  height: 14rem;
}
.item p {
  margin: 6px 0px;
}

.item-caption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0.3em;
}
.item-prices {
  display: flex;
  gap: 15px;
}
.item-price-new {
  color: #374151;
  font-size: 18px;
  font-weight: 600;
}
.item-price-old {
  color: #8c8c8c;
  font-size: 18px;
  font-weight: 500;
  text-decoration: line-through;
}

.item-purchase-value {
  font-size: 0.8em;
}

.item:hover {
  transform: scale(1.05);
  transition: 0.6s;
}

.discount-badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #ff4d4d;
  color: white;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
}

@media (max-width: 800px) {
  .item {
    width: 35%;
  }
  .item-price-old {
    font-size: 12px;
  }

  .item img {
    height: 10rem;
  }

  .item-prices {
    display: flex;
    gap: 0.3rem;
  }
}
@media (max-width: 500px) {
  .item p {
    font-size: 14px;
  }
  .item-price-new {
    font-size: 14px;
  }
}
