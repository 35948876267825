.address-selector-outer-container {
  margin: 2rem 0;
  border: 0.2rem solid rgb(255, 191, 0);
  padding: 2rem;
  width: inherit;
  border-radius: 1rem;

  .addresses {
    padding: 1.2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;

    .address {
      display: flex;
      gap: 2rem;
      font-size: 0.8rem;
      align-items: start;
    }
  }

  .selected-address {
    margin: 1.2rem 0;
  }

  .add-address {
    width: 25rem;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .form-field {
      input {
        height: 2rem;
        width: 100%;
        padding-left: 0.6rem;
        border: 1px solid #c9c9c9;
        outline: none;
        color: #5c5c5c;
        font-size: 0.8rem;
        box-sizing: border-box;
      }
    }
  }
  button {
    background-color: #200171;
    margin-bottom: rem;
    height: 2.2rem;
    width: 8rem;
    border: none;
    color: #ffffff;
  }
}

@media (max-width: 1024px) {
  .address-selector-outer-container {
    .add-address {
      width: 16rem;
      margin: 0.8rem 0;
    }
  }
}
