.payment-handler-outer-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 2rem;
  border: 0.2rem solid rgb(255, 191, 0);
  padding: 2rem;
  width: 90%;
  border-radius: 1rem;
  height: 20rem;

  .payment-handler-inner-container {
    display: flex;
    flex-direction: column;
    gap: 1.4rem;
    align-items: center;
  }

  .payment-method {
    background-color: #000000;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    // transform: rotateY(45deg) rotateX(25deg);
    color: white;
    font-family: Arial, sans-serif;
    transition: transform 0.5s;
  }
}
